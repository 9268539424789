import React from 'react'
import {orderApi} from '../api/order'
import { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

// - icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleXmark, faSpinner } from '@fortawesome/free-solid-svg-icons'

const SuccessOrder = (props) => {
  const checkout_session_id = props.cs_id
  const [order_data, set_order_data] = useState([])
  const [isCheking, setIsChecking] = useState(true)

  const check_stripe_order = () => {
    orderApi.check_order({cs_id : checkout_session_id}).then((res) => {
        setIsChecking(false)
        if(res.success){
            set_order_data(res.data)

            // remove local storage order data
            localStorage.removeItem('mimik_order')
        }
    })
  }
  useEffect(() => {
    check_stripe_order()
  }, [])

  return (
    <>
      <div className='row success-order-div'>
        <div className='col col-lg-12'>
          <div className='msg-content'>
            {
              isCheking ? 
                <>
                  <span className='check-icon'><FontAwesomeIcon icon={faSpinner} /></span>
                </>
              :
                <>
                  {
                    order_data.length ? 
                      <>
                        <span className='check-icon'><FontAwesomeIcon icon={faCircleCheck} /></span>
                        <h2>Thank you for ordering!</h2>
                        <p>We have sent your order summary to your email.</p>
                        <Link to={"/menu"}>Back to menu</Link>
                      </>
                    :
                    <>
                      <span className='check-icon'><FontAwesomeIcon icon={faCircleXmark} /></span>
                      <h2>Error occured!</h2>
                      <p>Please make an order first.</p>
                      <Link to={"/menu"}>Back to menu</Link>
                    </>
                  }
              </>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default SuccessOrder