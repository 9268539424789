import React, { useState } from 'react'
import { NavLink, useParams, useSearchParams, useOutletContext, Link } from 'react-router-dom';
import SuccessOrder from '../Components/SuccessOrder';
import CanceledOrder from '../Components/CanceledOrder'
import moment from 'moment';
import Form from 'react-bootstrap/Form';

// - datepicker
import { setHours, setMinutes } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// - icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';


const Checkout = () => {
  const saved_order = (localStorage.getItem('mimik_order') !== null) ? JSON.parse(localStorage.getItem('mimik_order')) : [];
  const [order_data, set_order_data] = useState(saved_order);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pickup_date, set_pickup_date] = useState('')
  const [pickup_time, set_pickup_time] = useState('')
  const [updateOrderCount] = useOutletContext();
  const [quantity, setQuantity] = useState();

  const [pageSectionNo, setPageSectionNo] = useState('')

  const changeInputPage = (btn_type) => {
    if (!btn_type) { return; }
    const page1 = document.getElementById('page-1')
    const page2 = document.getElementById('page-2')

    if (btn_type == 'back') {
      page1.classList.remove('hide-page')
      page2.classList.add('hide-page')

    } else if (btn_type == 'continue') {
      page2.classList.remove("hide-page")
      page1.classList.add("hide-page")
    }
  }

  const onSelectPickupDate = (date) => {
    var selectedDay = moment(new Date(date)).format('YYYY-MM-DD');
    set_pickup_date(selectedDay)
  }

  const onSelectPickupTime = (time) => {
    var selectedTime = moment(new Date(time)).format('HH:mm');
    set_pickup_time(selectedTime)
  }

  const updateOrderQuantity = (e) => {
    const currMenu = e.target
    const menuKey = currMenu.dataset.menuKey
    const quantityVal = currMenu.value
    // console.log(currMenu)
    // console.log(menuKey)
    // console.log(currMenu)

    if (quantityVal == 0) {
      var upt_saved_order = order_data.filter(object => {
        return object.menu_key !== menuKey;
      })

      // remove element
      // document.getElementById('order_item_' + menuKey).remove()
      set_order_data(upt_saved_order)

      // update order count in cart
      updateOrderCount(upt_saved_order.length)

      //update local storage
      localStorage.setItem('mimik_order', JSON.stringify(upt_saved_order))


    }
  }

  const removeItem = (e) => {
    e.preventDefault();
    const currMenu = e.target
    const menuKey = currMenu.dataset.menuKey

    var upt_saved_order = order_data.filter(object => {
      return object.menu_key !== menuKey;
    })

    // remove element
    // document.getElementById('order_item_' + menuKey).remove()
    set_order_data(upt_saved_order)

    // update order count in cart
    updateOrderCount(upt_saved_order.length)

    //update local storage
    localStorage.setItem('mimik_order', JSON.stringify(upt_saved_order))
  }

  const bookingTimes = (disable_times = []) => {
    let b_times = [];
    for (let t = 12; t <= 21; t++) {
      if (!disable_times.includes(t + ":00")) {
        b_times.push(setHours(setMinutes(new Date(), 0), t));
      }
      if (t < 21) {
        if (!disable_times.includes(t + ":30")) {
          b_times.push(setHours(setMinutes(new Date(), 30), t));
        }
      }
    }
    return b_times;
  }
  const [includeTimes, setIncludeTimes] = useState(bookingTimes);

  return (
    <section id="checkout" className="checkout">
      <div className="container" data-aos="fade-up">
        {(() => {
          if (searchParams.get('success') && searchParams.get('cs_id')) {
            return <SuccessOrder cs_id={searchParams.get('cs_id')} />

            // } else if (searchParams.get('canceled') && searchParams.get('cs_id')){
            //   return <CanceledOrder/>

          } else {
            return <>
              <div className="section-header">
                <p><span>PICK-UP ORDER</span></p>
              </div>
              <div className='orders'>
                {
                  order_data.length ?
                    <form method='POST' action='http://localhost:5003/create-checkout-session'>
                      <div className='row justify-content-md-center'>
                        <div className='col col-lg-8 col-sm-12' id='page-1'>
                          {order_data.map((order, idx) => (
                            <div className="order-item" key={idx} id={'order_item_' + order['menu_key']}>
                              <div className='row'>
                                <div className='col col-lg-6'>
                                  <h4>{order.name}</h4>
                                  <input type='hidden' name={'order-name-' + idx} value={order.name} />
                                  <div className='quantity'>
                                    <span>Quantity : </span>
                                    {/* <span className="d-flex">
                                      <button onClick={incrementValue} onChange={updateOrderQuantity}>+</button>
                                      <span name={'order-quantity-' + idx}  date-menu-key={order['menu_key']}>{quantity}</span>
                                      <button onClick={decrementValue} onChange={updateOrderQuantity}>-</button>
                                    </span> */}
                                    <select name={'order-quantity-' + idx} defaultValue={1} onChange={updateOrderQuantity} data-menu-key={order['menu_key']}>
                                      {/* <option value={0} >Remove</option> */}
                                      <option value={1} >1</option>
                                      <option value={2}>2</option>
                                      <option value={3}>3</option>
                                      <option value={4}>4</option>
                                      <option value={5}>5</option>
                                      <option value={6}>6</option>
                                      <option value={7}>7</option>
                                      <option value={8}>8</option>
                                      <option value={9}>9</option>
                                      <option value={10}>10</option>
                                    </select>
                                    <a
                                      className="trashcan"
                                      name={'order-quantity-' + idx}
                                      onClick={removeItem}
                                      data-menu-key={order['menu_key']}
                                    >
                                        <FontAwesomeIcon icon={faTrashCan} />
                                        Remove
                                    </a>
                                  </div>
                                </div>
                                <div className='col col-lg-6'>
                                  <div className='price-div'>
                                    <p className="price"> £{order.price} </p>
                                    <input type='hidden' name={'order-price-' + idx} value={order.price} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          <div className='form-btm'>
                            <NavLink to='/menu'>ADD MORE ITEM</NavLink>
                            <button className='submit' type='button' onClick={() => changeInputPage('continue')}>CONTINUE</button>
                          </div>
                        </div>
                        <div className='clearfix'></div>
                        <div className='col col-lg-8 col-sm-12 row pickdatetime hide-page' id='page-2'>
                          {/* <div className='col col-lg-12'>
                            <h4>Pickup Date & Time:</h4>
                          </div>
                          <div className='clearfix'></div>
                          <div className='col-xs-12 col-lg-6 mb-2'>
                            <DatePicker
                              className='form-control'
                              minDate={new Date()}
                              id="pickup_date"
                              name="pickup_date"
                              placeholderText="Date"
                              dateFormat='y-MM-dd hh:mm a'
                              onChange={(date) => onSelectPickupDate(date)}
                              value={pickup_date}
                              required
                            />
                          </div>
                          <div className='col-xs-12 col-lg-6 mb-2'>
                            <DatePicker
                              className='form-control'
                              id="pickup_time"
                              name="pickup_time"
                              placeholderText="Time"
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              onChange={(time) => onSelectPickupTime(time)}
                              value={pickup_time}
                              includeTimes={includeTimes}
                              required
                            />
                          </div> */}
                          <Form.Group className="mb-3" controlId="pickupForm.name">
                            <Form.Label>Name:</Form.Label>
                            <Form.Control type="text" placeholder="Enter name" required name='name' />
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="pickupForm.email">
                            <Form.Label>Email:</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" required name='email' />
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="pickupForm.phone_number">
                            <Form.Label>Phone Number:</Form.Label>
                            <Form.Control type="number" placeholder="Enter phone number" required name='phone_number' />
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="pickupForm.pickupDate">
                            <Form.Label>Pickup Date:</Form.Label>
                            <DatePicker
                              className='form-control'
                              minDate={new Date()}
                              id="pickup_date"
                              name="pickup_date"
                              placeholderText="Date"
                              dateFormat='y-MM-dd hh:mm a'
                              onChange={(date) => onSelectPickupDate(date)}
                              value={pickup_date}
                              required
                            />
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="pickupForm.pickupTime">
                            <Form.Label>Pickup Time:</Form.Label>
                            <DatePicker
                              className='form-control'
                              id="pickup_time"
                              name="pickup_time"
                              placeholderText="Time"
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              onChange={(time) => onSelectPickupTime(time)}
                              value={pickup_time}
                              includeTimes={includeTimes}
                              required
                            />
                          </Form.Group>


                          <div className='form-btm'>
                            <NavLink onClick={() => changeInputPage('back')}>BACK</NavLink>
                            <button className='submit' type='submit'>CHECKOUT</button>
                          </div>
                        </div>
                      </div>

                      {/* <div className='form-btm'>
                        <NavLink to='/menu'>ADD MORE ITEM</NavLink>
                        <button className='submit' type='submit'>CONTINUE</button>
                      </div> */}
                    </form>
                    :
                    <div className='no-order-item'>
                      <p>No order item yet.</p>
                      <NavLink to='/menu'>ADD ORDER</NavLink>
                    </div>
                }
              </div>
            </>
          }
        })()}
      </div>
    </section>
  )
}

export default Checkout