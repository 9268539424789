import { handleResponse } from "../_utils/handle-response";

const API_URL = process.env.REACT_APP_API_URL

function check_order(data) {
    const requestOptions = { method: 'POST',headers: {'Content-Type': 'application/json'}, body: JSON.stringify(data)};
    return fetch(`${API_URL}/checkout/check_order`, requestOptions).then(handleResponse).then(data => {return data});
}

export const orderApi = {
    check_order
}; 