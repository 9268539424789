import React, { useEffect, useState, lazy } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import moment from 'moment';
import Swal from 'sweetalert2';
import SEO from '../Components/SEO';

//- api
import { bookingApi } from '../api/booking'
import { reservation_setting } from '../api/reservation_setting';

// - datepicker
import { setHours, setMinutes } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import pages
// const BookAtActon = lazy(() => import("./components/BookAtActon"));
// const BookAtRichmond = lazy(() => import("./components/BookAtRichmond"));
import BookAtActon from './components/BookAtActon';
import BookAtRichmond from './components/BookAtRichmond';

// - locale timezone
var momentTZ = require('moment-timezone');
var userTimeZone = "Europe/London";

function Booking() {
    const [errMsg, setErrMsg] = useState("");
    const [bookingSuccess, isBookingSuccess] = useState(false);
    const [reserveDate, setReserveDate] = useState('');
    const minTimeReservation = setHours(setMinutes(new Date(), 0), 11);
    const maxTimeReservation = setHours(setMinutes(new Date(), 0), 22);
    const [err_reservation_time, set_err_reservation_time] = useState('');
    const [disable_booking, is_disable_booking] = useState(true);
    const [bookingLocation, setBookingLocation] = useState('acton');
    const setBookingLocationOfResos = (location) => {
        setBookingLocation(location);
    }
    const bookForm = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            no_of_tables: '',
            no_of_persons: '',
            status: 1
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            first_name: Yup.string().max(20, 'Must be 20 character or less').required('First name is required'),
            last_name: Yup.string().max(20, 'Must be 20 character or less').required('Last name is required'),
            email: Yup.string().max(40, 'Must be 20 character or less').required('Email is required'),
            phone_number: Yup.number().required("Phone number is required"),
            no_of_tables: Yup.number().required("No. of tables is required"),
            no_of_persons: Yup.number().required("No. of people is required"),
        }),
        onSubmit: (values) => {
            if (err_reservation_time) { return; }
            if (reserveDate) {
                values.reserve_date = moment(reserveDate).format('YYYY-MM-DD H:mm');
            } else {
                setErrMsg('Reserve date is required');
                return;
            }
            bookingApi.addBooking(values).then((data) => {
                if (data.success) {
                    Swal.fire({
                        text: data.msg,
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#ff6766',
                    })
                    isBookingSuccess(true)
                } else {
                    setErrMsg(data.msg);
                    return;
                }
            });
        }
    });

    const isWeekday = (date) => {
        const day = date.getDay();
        return day !== 2;
    }

    const bookingTimes = (disable_times = []) => {
        let b_times = [];
        for (let t = 11; t <= 22; t++) {
            // b_times.push(setHours(setMinutes(new Date(), 0), t));
            // b_times.push(setHours(setMinutes(new Date(), 30), t));
            // b_times.push(t + ':00');
            // b_times.push(t + ':30');
            if (!disable_times.includes(t + ":00")) {
                b_times.push(setHours(setMinutes(new Date(), 0), t));
            }
            if (!disable_times.includes(t + ":30")) {
                b_times.push(setHours(setMinutes(new Date(), 30), t));
            }
        }
        return b_times;
    }

    const [includeTimes, setIncludeTimes] = useState(bookingTimes);
    const onChangeDate = (date) => {
        var disableBookingTime = momentTZ().tz(userTimeZone).format('20:30:00'); //6pm
        var currentTime = momentTZ().tz(userTimeZone).format('HH:mm:ss');
        var currentDay = momentTZ().tz(userTimeZone).format('YYYY-MM-DD');
        var selectedDay = moment(new Date(date)).format('YYYY-MM-DD');
        var selectedHour = moment(new Date(date)).format('HH:mm');

        if (!includeTimes.includes(selectedHour)) {
            set_err_reservation_time("Invalid reservation time")
        } else {
            set_err_reservation_time("")
        }

        if (currentDay == selectedDay && currentTime >= disableBookingTime) {
            setIncludeTimes([]);
            setReserveDate('');
        } else {
            reservation_setting.getSetting(selectedDay).then((data) => {
                console.log(data)
                if (data.success) {
                    var disable_times = JSON.parse(data.data['disable_times'])
                    var status = data.data['status'];
                    if (status == 1) {
                        setIncludeTimes(bookingTimes(disable_times));
                    } else {
                        setIncludeTimes([])
                    }
                }
            })
            setReserveDate(date);
        }
    }

    return (
        <section id="booking" className="booking p-5" style={{ overflow: "initial" }}>
            <SEO
                title="Book a table at Mimik Sushi | Acton"
                description="Book a table at Mimik Sushi"
                name="Mimik Sushi"
                type="Booking page"
            />
            <div className="container" data-aos="fade-up">

                <div className="section-header">
                    <p><span>BOOK A TABLE</span></p>
                </div>

                <div className="menu justify-content-center d-flex mx-auto my-5 row">
                    <button onClick={() => setBookingLocationOfResos('acton')} className={`btn-menu mx-5 my-2 btn-lg ${bookingLocation === 'acton' ? 'location-menu' : ''}`}>Book at Acton</button>
                    <button onClick={() => setBookingLocationOfResos('richmond')} className={`btn-menu mx-5 my-2 btn-lg ${bookingLocation === 'richmond' ? 'location-menu' : ''}`}>Book at Richmond</button>
                </div>

                {/* resOS Booking widget script START */}
                <div className="justify-content-center my-auto">

                    {
                        bookingLocation === 'acton' ?
                            <>
                                <BookAtActon />
                            </>
                            :
                            <>
                                <BookAtRichmond />
                            </>
                    }

                </div>
                {/* resOS Booking widget script END */}

                {/* {bookingSuccess ?
                    <div className="text-center">
                        <p className="text-success fs-1">Thank you for booking with us!</p>
                        <p className="fs-5">Kindly check your email for booking details</p>
                    </div>
                    :
                    ''
                } */}

                {/* <div className="row gy-4">
                    {errMsg && !bookingSuccess ? <div className='book-a-table error-message'> {errMsg}</div> : ''}
                </div> */}

                {/* <div className="row gy-4">
                    {disable_booking ? <div className='book-a-table error-message text-center'> The booking system is under maintenance. Sorry for the inconvenience.</div> : ''}
                </div> */}

                {/* <div className="row gy-4">
                    {disable_booking ? <div className='book-a-table error-message text-center'> The booking system is under maintenance. Sorry for the inconvenience.</div> : ''}
                </div> */}

                {/* {
                    !bookingSuccess ?
                        <form className="php-email-form p-3 p-md-4">
                            <div className="row">
                                <div className="col-xl-6 form-group">
                                    <input
                                        type="text"
                                        name="first_name"
                                        className="form-control"
                                        id="first_name"
                                        placeholder="First Name"
                                        required
                                        onChange={bookForm.handleChange}
                                        onBlur={bookForm.handleBlur}
                                        value={bookForm.values.first_name}
                                    />
                                    {bookForm.touched.first_name && bookForm.errors.first_name ? <p className='field-err-msg'>{bookForm.errors.first_name}</p> : null}
                                </div>
                                <div className="col-xl-6 form-group">
                                    <input
                                        type="text"
                                        name="last_name"
                                        className="form-control"
                                        id="last_name"
                                        placeholder="Last Name"
                                        required
                                        onChange={bookForm.handleChange}
                                        onBlur={bookForm.handleBlur}
                                        value={bookForm.values.last_name}
                                    />
                                    {bookForm.touched.last_name && bookForm.errors.last_name ? <p className='field-err-msg'>{bookForm.errors.last_name}</p> : null}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-6 form-group">
                                    <input
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        id="email"
                                        placeholder="Email"
                                        required
                                        onChange={bookForm.handleChange}
                                        onBlur={bookForm.handleBlur}
                                        value={bookForm.values.email}
                                    />
                                    {bookForm.touched.email && bookForm.errors.email ? <p className='field-err-msg'>{bookForm.errors.email}</p> : null}
                                </div>
                                <div className="col-xl-6 form-group">
                                    <input
                                        type="number"
                                        name="phone_number"
                                        className="form-control"
                                        id="phone_number"
                                        placeholder="Phone number"
                                        required
                                        onChange={bookForm.handleChange}
                                        onBlur={bookForm.handleBlur}
                                        value={bookForm.values.phone_number}
                                    />
                                    {bookForm.touched.phone_number && bookForm.errors.phone_number ? <p className='field-err-msg'>{bookForm.errors.phone_number}</p> : null}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-3 form-group">
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="no_of_tables"
                                        id="no_of_tables"
                                        placeholder="No. of tables"
                                        required
                                        onChange={bookForm.handleChange}
                                        onBlur={bookForm.handleBlur}
                                        value={bookForm.values.no_of_tables}
                                    />
                                    {bookForm.touched.no_of_tables && bookForm.errors.no_of_tables ? <p className='field-err-msg'>{bookForm.errors.no_of_tables}</p> : null}
                                </div>
                                <div className="col-xl-3 form-group">
                                    <input
                                        type="number"
                                        name="no_of_persons"
                                        className="form-control"
                                        id="no_of_persons"
                                        placeholder="No. of people"
                                        required
                                        onChange={bookForm.handleChange}
                                        onBlur={bookForm.handleBlur}
                                        value={bookForm.values.no_of_persons}
                                    />
                                    {bookForm.touched.no_of_persons && bookForm.errors.no_of_persons ? <p className='field-err-msg'>{bookForm.errors.no_of_persons}</p> : null}
                                </div>
                                <div className="col-xl-6 form-group">
                                    <DatePicker
                                        className='form-control'
                                        selected={reserveDate}
                                        showTimeSelect
                                        onChange={onChangeDate}
                                        value={reserveDate}
                                        minDate={new Date()}
                                        id="reserve_date"
                                        name="reserve_date"
                                        placeholderText="Select booking date and time"
                                        dateFormat='y-MM-dd hh:mm a'
                                        // minTime={minTimeReservation}
                                        // maxTime={maxTimeReservation}
                                        includeTimes={includeTimes}
                                        filterDate={isWeekday}
                                    />
                                    {err_reservation_time ? <p className='field-err-msg'>{err_reservation_time}</p> : null}
                                </div>
                            </div>
                            <div className="text-center">
                                <button type="submit" onClick={bookForm.handleSubmit} >Submit</button>
                            </div>
                        </form>
                        : ''
                } */}
                {/*End Contact Form */}
            </div>
        </section >

    )
}

export default Booking