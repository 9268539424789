import React from 'react'
import SEO from '../Components/SEO'
import { HelmetProvider } from 'react-helmet-async'

const ErrorPage = () => {
  return (
    <div className="position-absolute top-50 start-50 translate-middle text-center">
      <HelmetProvider>
        <SEO
          title="Page not found | Mimik Sushi"
          description="Error page of Mimik Sushi"
          name="Mimik Sushi"
          type="Error Page"
        />
      </HelmetProvider>
      <h1 className="text-danger">Oops!</h1>
      <h3>404 - PAGE NOT FOUND</h3>
      <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable</p>
      <a href="mimiksushi.com" className="text-decoration-none btn btn-danger">GO TO HOMEPAGE</a>
    </div>
  )
}

export default ErrorPage